import mixpanel from 'mixpanel-browser';
import AnalyticsProviderInterface from './interface';

const PROVIDER_NAME = 'mixpanel';

/**
 * Wraps the mixpanel api for sending usage data
 *
 */
class Mixpanel extends AnalyticsProviderInterface {
  constructor(initOptions) {
    super(initOptions);
  }

  init(options = {}) {
    if (!options.token) {
      throw new Error('Mixpanel token is not defined');
    }
    return mixpanel.init(options.token, options.config);
  }

  get name() {
    return PROVIDER_NAME;
  }

  hasOptedOut() {
    return mixpanel.has_opted_out_tracking();
  }

  optOut(options) {
    // Opt a user out of data collection
    this.track('OptOutTracking');
    mixpanel.opt_out_tracking(options);
  }

  getDistinctId() {
    return mixpanel.get_distinct_id();
  }

  optIn(options) {
    // Opt the user in to data tracking
    this.track('OptInTracking');
    mixpanel.opt_in_tracking(options);
  }

  track(event, properties) {
    mixpanel.track(event, properties);
  }

  /**
   * Can be called by a product to identify the user
   * @param {string} distinctId
   */
  identify(distinctId) {
    mixpanel.identify(distinctId);
  }
}

let MIXPANEL_TOKEN = null;
switch (BUILD_FLAG__BUILD_TYPE) {
  case 'Production':
  case 'fluent':
  case 'Development':
  case 'Staging':
    // This is the staging token
    MIXPANEL_TOKEN = '7cecb637d6468a8b61f388bbb82072ee';
    break;
  default:
    // Mixpanel is turned off for PR / Local builds
}

// default storage is cookie
const options = { token: MIXPANEL_TOKEN, config: { persistence: 'localStorage', opt_out_tracking_by_default: true }};

export { Mixpanel, options };

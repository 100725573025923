import { getGlobal } from "../compat";


var _useLeftHandedInput = false;
var __firefoxLMBfix = false;


/** 
 * Contains static utility functions for DOM events.
 *
 * @memberof Autodesk.Viewing
 * @alias Autodesk.Viewing.EventUtils
 */
export class EventUtils {


	/**
	 * @param {DOMEvent} event - A browser-triggered event
	 * @returns {boolean} true when the event matches a secondary-button click.
	 *
	 * @alias Autodesk.Viewing.EventUtils#isRightClick
	 */
	static isRightClick(event) {
	    var _window = getWindowFromEvent(event);
	    if (!(event instanceof _window.MouseEvent))
	        return false;

	    var button = event.button;

	    // Check for Firefox spoof: Control+LMB converted to RMB.
	    // The "buttons" property in Firefox will include 1 for LMB and 2 for RMB.
	    if( "buttons" in event ) {
	        // For button down the 1 bit will be on indicating LMB.
	        // For button up it's off so check the flag to see if we
	        // switched the down event.
	        if( __firefoxLMBfix && !(event.buttons & 1) ) { // Button up?
	            __firefoxLMBfix = false;
	            button = 0;
	        }
	        else if( (button === 2) && (event.buttons & 1) ) {
	            button = 0;    // Convert back to reality.
	            __firefoxLMBfix = true;
	        }
	    }

	    var rightButton = _useLeftHandedInput ? 0 : 2;

	    return button === rightButton;
	}


	/**
	 * @param {DOMEvent} event - A browser-triggered event
	 * @returns {boolean} true when the event matches a middle-button mouse click.
	 *
	 * @alias Autodesk.Viewing.EventUtils#isMiddleClick
	 */
	static isMiddleClick(event) {
	    var _window = getWindowFromEvent(event);
	    if (!(event instanceof _window.MouseEvent))
	        return false;

	    return event.button === 1;
	}


	/**
	 * Internally used function to set UX for left-hander users.
	 * @param {boolean} value - true to switch left and right buttons.
	 *
	 * @alias Autodesk.Viewing.EventUtils#setUseLeftHandedInput
	 *
	 * @private
	 */
	static setUseLeftHandedInput(value) {
		_useLeftHandedInput = value;
	}

}


/**
 * @private
 */
function getWindowFromEvent(event) {
    var _document = event.target && event.target.ownerDocument;
    if (_document) {
        return _document.defaultView || _document.parentWindow;
    }
    return getGlobal();
}


// Backwards compatibility - TODO: Remove in v8.0.0
Autodesk.Viewing.isRightClick = EventUtils.isRightClick;
Autodesk.Viewing.isMiddleClick = EventUtils.isMiddleClick;


export default class ProviderInterface {
	constructor(initOptions = {}) {
		this.initOptions = initOptions;
	}
	init() {}
	get name() {}
	optIn(options) {}
	optOut(options) {}
	hasOptedOut() {}
	getDistinctId() {}
	track(event, properties) {}
	identify(distinctId) {}
}